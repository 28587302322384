<template>
  <div :data-id="model.id" class="article-item">
    <a :href="model.link.link" class="article-item-cover">
      <image-box :model="model.image"></image-box>
      <div class="article-item-cover-content">
        <h4 class="article-item-title" v-html="model.title"></h4>
        <div class="article-item-cover-footer">
          <span class="article-item-date">{{ model.date?.shortDate }}</span>
          <span class="article-item-duration"></span>
        </div>
      </div>
    </a>
    <p class="article-item-description" v-html="model.text"></p>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "ArticleItem",
  components:{ImageBox},
  props: {
    model: Object
  },
}
</script>