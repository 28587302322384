<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section fill auto">
    <div :class="('t-' + model.fontColor.cssClass)" class="section-content sm mt-120 mb-120 text-center">
      <h2 v-if="showBigHeader" class="mb-50" v-html="model.title"></h2>
      <h4 v-if="showBigHeader && model.text" class="mb-50 w-500" v-html="model.text"></h4>
      <h6 v-if="showSmallHeader" class="mb-50" v-html="model.title"></h6>
      <p v-if="showSmallHeader && model.text" class="lg w-300 mb-50" v-html="model.text"></p>
      <newsletter-form :model="model.form"></newsletter-form>
    </div>
    <div v-if="model.backgroundImage" class="section-cover">
      <image-box :model="model.backgroundImage"></image-box>
    </div>
  </div>
</template>

<script>
import NewsletterForm from "@/main/forms/NewsletterForm";
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "NewsletterBlockElement",
  components: {NewsletterForm, ImageBox},
  props: {
    model: Object
  },
  data: () => ({
    showBigHeader: false,
    showSmallHeader: false,
  }),
  mounted() {
    this.showBigHeader = this.model.titleStyle === 'big';
    this.showSmallHeader = this.model.titleStyle === 'compact';
  },
}
</script>