const animations = {
  fadeAnimations: [
    "fade",
    "fade-up",
    "fade-down",
    "fade-left",
    "fade-right",
    "fade-up-right",
    "fade-up-left",
    "fade-down-right",
    "fade-down-left"],
  flipAnimations: [

    "flip-up",
    "flip-down",
    "flip-left",
    "flip-right"],
  slideAnimations: [
    "slide-up",
    "slide-down",
    "slide-left",
    "slide-right"],
  zoomAnimations: [
    "zoom-in",
    "zoom-in-up",
    "zoom-in-down",
    "zoom-in-left",
    "zoom-in-right",
    "zoom-out",
    "zoom-out-up",
    "zoom-out-down",
    "zoom-out-left",
    "zoom-out-right"],
};
export const getAnimation = () => {
  // const keys = Object.keys(animations);
  // const animationType = keys[Math.floor(Math.random() * keys.length)];
  // const animationIndex = Math.floor(Math.random() * animations[animationType].length);
  //
  // return animations[animationType][animationIndex];
  return animations.fadeAnimations[1];
};
