<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="articles-slider-container ">
    <div class="track-container">
      <splide :has-track="false" :options="options" class="section-content lg">
        <splide-track>
          <splide-slide v-for="(article, index) in model.articles" :key="index">
            <div class="slide-container">
              <div class="text-container">
                <p class="title"> {{ article.title }}</p>
                <p class="text">{{ article.text }}</p>
                <v-btn variant="outlined" class="button" @click="readMore">{{
                    $store.state.translations['article.Buttons.ReadMore']
                  }}
                </v-btn>
              </div>
              <div>
                <div class="image-container" :style="{backgroundImage:`url(${article.image.baseUrl})`}">
                </div>
              </div>
            </div>
          </splide-slide>
        </splide-track>
        <div class="splide__arrows arrow-container">
          <button class="splide__arrow splide__arrow--prev">
            <v-icon icon="west" class="arrow" />
          </button>
          <button class="splide__arrow splide__arrow--next">
            <v-icon icon="east" class="arrow" />
          </button>
        </div>
      </splide>
    </div>
  </div>
</template>

<script>

import "./ArticlesSliderBlockElement.scss";
import { SplideTrack } from "@splidejs/vue-splide";

export default {
  name: "ArticlesSliderBlockElement.vue",
  components: { SplideTrack },
  props: {
    model: Object,
  },
  data: () => {
    return {
      options: {
        type: "loop",
        padding: "5rem",
      },
    };
  },

  methods: {
    readMore () {
      console.log("czytaj więcej"); // todo: add link or other logic
    },
  },
};
</script>

<style scoped>

</style>
