<template>
  <div :data-id="model.id">
    <image-box :css-class="'stretch'" :model="model.image"></image-box>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";

export default {
  name: "ImageBlockElement",
  components: {ImageBox},
  props: {
    model: Object
  }
}
</script>