<template>
  <div class="login">
    <div v-if="!$vuetify.display.mobile" class="login-cover">
      <image-box :model="model"></image-box>
    </div>
    <div class="login-panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "MemberPage",
  components: { ImageBox },
  props: {
    model: Object

  }
}
</script>