<template>
  <div class="login-form">
    <h1 class="mb-45" v-html="model.title"></h1>
    <form class="w-100" @submit.prevent="submitForm">
      <div class="mb-5">
        <v-text-field
            v-model="email"
            :label="model.translations['loginPage.Placeholders.Email']"
            type="email"
            disabled
            variant="outlined"
        ></v-text-field>
      </div>
      <div class="bh_divider">
        {{ model.translations["loginPage.Labels.CreatePassword"] }}
      </div>
      <div class="mb-5">
        <div class="bh_password__requirements mb-3">{{ model.translations["loginPage.Labels.PasswordRequirements"] }}</div>
        <v-text-field
            v-model="form.password"
            class="mb-5"
            :error-messages="passwordErrors"
            :label="model.translations['registerPage.Placeholders.Password']"
            type="password"
            variant="outlined"
            @input="v$.form.password.$touch()"
        ></v-text-field>
        <v-text-field
            v-model="form.confirmPassword"
            :error-messages="confirmPasswordErrors"
            :label="model.translations['registerPage.Placeholders.ConfirmPassword']"
            type="password"
            variant="outlined"
            @input="v$.form.confirmPassword.$touch()"
        ></v-text-field>
      </div>
      <div class="btn-wrapper">
        <v-btn class="pa-2 w-100" color="primary" size="large" type="submit">
          {{ model.translations['loginPage.Buttons.Login'] }}
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required, sameAs} from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "ActivateAccountForm",
  props: {
    model: Object,
  },
  data: () => ({
    v$: useVuelidate(),
    submitted: false,
    responseErrors: [],
    email: null,
    form: {
      activationCode: null,
      password: null,
      confirmPassword: null,
    },
  }),
  mounted() {
    this.email = this.model.email;
    this.form.activationCode = this.model.activationCode;
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.v$.form.password.$dirty)
        return errors;
      this.v$.form.password.$invalid && errors.push(this.model.translations["portal.ValidationMessages.RequiredField"]);
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.v$.form.confirmPassword.$dirty)
        return errors;
      this.v$.form.confirmPassword.required.$invalid && errors.push(this.model.translations["portal.ValidationMessages.RequiredField"]);
      this.v$.form.confirmPassword.sameAs.$invalid && errors.push(this.model.translations["registerPage.Errors.InvalidConfirmPassword"]);
      return errors;
    },
  },
  validations() {
    return {
      form: {
        password: {
          required
        },
        confirmPassword: {
          required,
          sameAs: sameAs(this.form.password)
        },
      }
    };
  },
  methods:{
    async submitForm() {
      this.v$.$reset();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$errorToast(this.model.translations["portal.ValidationMessages.InvalidForm"]);
        return;
      }
      let current = this;
      current.$setBusy(true);
      axios({
        method: "POST",
        url: current.model.endpoint,
        data: { ...current.form },
      }).then(function (response) {
        if (response.status === 200) {
          current.v$.$reset();
          current.$setBusy(false);
          window.location.href = current.model.loggedUserRedirect;
        }
      }).catch(function (error) {
        if (error.response.data.errors) {
          Object.entries(error.response.data.errors).map((item) => {
            item[1].forEach((error) => {
              current.$errorToast(current.model.translations[error]);
            });
          });
        }
        if (error.response.data.detail) {
          current.$errorToast(current.model.translations[error.response.data.detail]);
        }
        current.$setBusy(false);
      });
    } 
  }
}
</script>