import {aliases, md} from 'vuetify/lib/iconsets/md'
// Vuetify
import {createVuetify} from 'vuetify'

export default createVuetify({
        theme: {
            defaultTheme: 'behappmePortal',
            themes: {
                behappmePortal: {
                    dark: false,
                    colors: {
                        background: '#FFFFFF',
                        primary: '#030087',
                        secondary: '#1B2559',
                        success: '#4ECB71',
                        error: '#FF2358',
                        warning: '#FF900D',
                        info: '#1B80E4',
                        surface: '#FFFFFF',
                        'on-background': '#02013F',
                        'on-surface': '#02013F',
                        'deep-blue': '#030087',
                        'icon-set': '#5B59B1',
                        'main-green': '#D2FB59'
                    },
                },
            },
        },
        icons: {
            defaultSet: 'md',
            aliases,
            sets: {
                md,
            }
        },
        display: {
            mobileBreakpoint: 'sm',
        }
    }
)
