<template>
  <div v-bind:id="model.anchor" :data-id="model.id"
  >
    <div v-if="!$vuetify.display.mobile" class="section vh-40">
      <div class="section-cover">
        <image-box :model="model.image"></image-box>
      </div>
    </div>
    <div :class="model.backgroundColor.cssClass" class="section auto fill">
      <div :class="('t-' + model.fontColor.cssClass)"
           class="section-content">
        <div class="auto-grid gap-80 mt-120 mb-120 max-3">
          <kpi-tile :data-aos="getAnimation()"
                    data-aos-delay="300"
                    data-aos-duration="1000"

                    v-for="(item, index) of model.tiles" :key="index" :model="item"></kpi-tile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KpiTile from "@/main/components/KpiTile";
import ImageBox from "@/main/components/ImageBox";
import { getAnimation } from "@/common/aosAnimations/aosAnimation";

export default {
  name: "IndicatorsBlockElement",
  methods: { getAnimation },
  components: { KpiTile, ImageBox },
  props: {
    model: Object,
  },
};
</script>
