<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section auto mt-90 mb-120">
    <div class="section-content lg">
      <h3 v-if="model.title" class="mb-50" v-html="model.title"></h3>
      <div class="auto-grid max-3 gap-20">
        <article-item v-for="(item, index) of articles" :key="index" :model="item"/>
      </div>
      <div v-if="totalPages > currentPage" class="d-flex justify-center mt-40">
        <v-btn
            append-icon="add"
            variant="text"
            @click="loadMore()">
          {{ model.translations['generic.Labels.LoadMore'] }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleItem from "@/main/components/ArticleItem";
import axios from "axios";

export default {
  name: "ArticleListBlockElement",
  components: {ArticleItem},
  props: {
    model: Object,
    pageAlias: String 
  },
  data: () => ({
    currentPage: 1,
    totalPages: 1,
    articles: [],
  }),
  mounted() {
    this.currentPage = 1;
    this.loadArticles();
  },
  computed: {
    categoriesQuery() {
      return this.model.categories.map((item) => item.id).join(",");
    }
  },
  methods: {
    loadArticles() {
      let current = this;
      current.$setBusy(true);
      axios({
        method: "GET",
        url: this.model.endpoint,
        params: {
          page: current.currentPage,
          pageSize: current.model.limit,
          categories: current.categoriesQuery,
          alias: current.pageAlias,
          assignedContentItem: current.model.assignedContentItemId
        }
      }).then(function (response) {
        if (response.status === 200) {
          current.articles = response.data.records;
          current.totalPages = response.data.totalPages;
        }
        current.$setBusy(false);
      })
    },
    loadMore() {
      this.currentPage += 1;
      let current = this;
      current.$setBusy(true);
      axios({
        method: "GET",
        url: this.model.endpoint,
        params: {
          page: current.currentPage,
          pageSize: current.model.limit,
          culture: current.model.culture,
          categories: current.categoriesQuery
        }
      }).then(function (response) {
        if (response.status === 200) {
          current.articles = current.articles.concat(response.data.records);
          current.totalPages = response.data.totalPages;
          current.$setBusy(false);
        }
      })
    }
  }
}
</script>