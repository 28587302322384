<template>
  <img :class="cssClass" :alt="model?.alternativeText" :src="model?.baseUrl" />
</template>

<script>
export default {
  name: "ImageBox",
  props: {
    model: Object,
    cssClass: String,
  },
};
</script>
