import { useToast } from "vue-toastification";

const toast = useToast();

export default {
    install: (app) => {
        app.config.globalProperties.$successToast = (text) => {
            toast.success(text);
        }

        app.config.globalProperties.$errorToast = (text) => {
            toast.error(text);
        }
    }
}