<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section auto mt-50 mb-50">
    <div class="section-content lg">
      <div class="auto-grid max-2 gap-140 wrap-reverse">
        <div>
          <image-box :model="model.article.image"></image-box>
        </div>
        <div>
          <div class="btn-wrapper mb-20">
            <div v-for="(category, index) of model.article?.categories" :key="index" class="btn sm secondary">
              {{ category.name }}
            </div>
          </div>
          <h3 class="mb-20" v-html="model.article?.title"></h3>
          <h6 class="mb-50 font-secondary">{{ model.article?.date.shortDate }}</h6>
          <p v-html="model.article?.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "FeaturedArticleBlockElement",
  components:{ImageBox},
  props: {
    model: Object
  }
}
</script>