<template>
  <div class="login-form">
    <h1 v-if="!submitted" class="mb-45" v-html="model.title"></h1>
    <form v-if="!submitted" class="w-100" @submit.prevent="submitForm">
      <div class="mb-5">
        <v-text-field
            v-model="form.password"
            :error-messages="passwordErrors"
            :label="model.translations['registerPage.Placeholders.Password']"
            type="password"
            variant="outlined"
        ></v-text-field>
      </div>
      <div class="mb-5">
        <v-text-field
            v-model="form.confirmPassword"
            :error-messages="confirmPasswordErrors"
            :label="model.translations['registerPage.Placeholders.ConfirmPassword']"
            type="password"
            variant="outlined"
        ></v-text-field>
      </div>
      <div class="login-form__buttons w-100 my-5 d-flex flex-column">
        <v-btn class="pa-2 w-100" color="primary" size="large" type="submit">
          {{ model.translations['forgotPasswordPage.Buttons.ResetPassword'] }}
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required,sameAs} from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "ForgotPasswordForm",
  props: {
    model: Object,
  },
  data: () => ({
    v$: useVuelidate(),
    submitted: false,
    form: {
      password: null,
      confirmPassword: null,
      resetKey: null,
      id: null,
      culture: null
    },
  }),
  mounted() {
    this.form.culture = this.model.culture;
    const urlParams = new URLSearchParams(window.location.search);
    this.form.resetKey = urlParams.get("key");
    this.form.id = urlParams.get("id");
  },
  computed: {
    passwordErrors() {
      const errors = []
      if (!this.v$.form.password.$dirty) return errors
      this.v$.form.password.$invalid && errors.push(this.model.translations["portal.ValidationMessages.RequiredField"])
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.v$.form.confirmPassword.$dirty) return errors
      this.v$.form.confirmPassword.$invalid && errors.push(this.model.translations["registerPage.Errors.InvalidConfirmPassword"])
      return errors
    },
  },
  validations() {
    return {
      form: {
        password: {required},
        confirmPassword: {required, sameAs: sameAs(this.form.password)}
      }
    };
  },
  methods: {
    async submitForm() {
      this.v$.$reset()
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$errorToast(this.model.translations['portal.ValidationMessages.InvalidForm']);
        return;
      }
      let current = this;
      current.$setBusy(true);
      axios({
        method: "POST",
        url: current.model.endpoint,
        data: {...current.form},
      }).then(function (response) {
        if (response.status === 200) {
          current.v$.$reset();
          current.$setBusy(false);
          current.$successToast(current.$store.state.translations['forgotPasswordPage.PasswordReset.Success']);
          setTimeout(() => {
            window.location.href = current.model.loginPageUrl;
          }, 5000);
        }
      }).catch(function (error) {
        if (error.response.data.errors) {
          Object.entries(error.response.data.errors).map((item) => {
            item[1].forEach((error) => {
              current.$errorToast(current.model.translations[error]);
            });
          });
        }
        if (error.response.data.detail) {
          current.$errorToast(current.model.translations[error.response.data.detail]);
        }
        current.$setBusy(false);
      })
    }
  }
}
</script>