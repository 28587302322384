<template>
  <div class="social-media">
    <share-network :url="urlAddress" network="linkedIn" title="Behapp.Me">
      <svg-icon class="social-media-icon" :icon="'linkedin'" />
    </share-network>
    <share-network :url="urlAddress" network="facebook" title="Behapp.Me">
      <svg-icon class="social-media-icon" :icon="'facebook'" />
    </share-network>
    <share-network :url="urlAddress" network="twitter" title="Behapp.Me">
      <svg-icon class="social-media-icon" :icon="'twitter'" />
    </share-network>
    <svg-icon class="social-media-icon" :icon="'link'" @click="copyLink" />
  </div>
</template>

<script>

import SvgIcon from '@/main/components/SvgIcon.vue';

export default {
  name: 'SocialMedia',
  components: { SvgIcon },
  computed: {
    urlAddress () {
      return window.location.href;
    },
  },
  methods: {
    copyLink () {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href);
        this.$successToast(this.$store.state.translations['generic.Toasts.Successes.CopiedLink']);
      } else {
        this.$errorToast(this.$store.state.translations['generic.Toasts.Errors.CantCopyLink']);
      }
    },
  },
};
</script>

