<template>
<div :data-id="model.id">
  <p v-html="model.text"></p>
</div>
</template>

<script>
export default {
  name: "ArticleTextBlockElement",
  props:{
    model: Object
  }
}
</script>