<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section auto pt-80 pb-80">
    <div class="section-content">
      <div class="auto-grid max-2 gap-140">
        <div>
          <div class="mb-50">
            <logo-component/>
          </div>
          <h3 class="mb-45" v-html="model.title"></h3>
          <p>{{ model.form.translations['contactForm.Placeholders.Email'] }}</p>
          <h4 class="mb-45 w-400 font-primary">{{ model.email }}</h4>
          <p><a class="t-primary" :href="model.form.privacyPolicy.link" target="_blank">{{model.form.translations['reCaptcha.PrivacyPolicy']}}</a></p>
        </div>

        <form ref="contactUsForm" novalidate @submit.prevent="submitForm">
          <h5 class="mb-75" v-html="model.form.title"></h5>
          <div class="input-wrapper">
            <v-text-field
                v-model="form.name"
                :error-messages="nameErrors"
                :label="model.form.translations['contactForm.Placeholders.Name']"
                required
                variant="outlined"
                @blur="v$.form.name.$touch()"
                @input="v$.form.name.$touch()"
            ></v-text-field>
            <v-text-field
                v-model="form.email"
                :error-messages="emailErrors"
                :label="model.form.translations['contactForm.Placeholders.Email']"
                required
                variant="outlined"
                @blur="v$.form.email.$touch()"
                @input="v$.form.email.$touch()"
            ></v-text-field>
            <v-textarea
                v-model="form.message"
                :error-messages="messageErrors"
                :label="model.form.translations['contactForm.Placeholders.Message']"
                required
                rows="4"
                variant="outlined"
                @blur="v$.form.message.$touch()"
                @input="v$.form.message.$touch()"
            ></v-textarea>
            <div class="d-flex flex-wrap align-center">
              <v-checkbox
                  v-model="form.acceptPrivacyPolicy"
                  :error-messages="acceptPrivacyPolicyErrors"
                  required
                  @blur="v$.form.acceptPrivacyPolicy.$touch()"
                  @change="v$.form.acceptPrivacyPolicy.$touch()"
              >
                <template v-slot:label>
                  <div v-html="model.form.privacyPolicy.text"></div>
                </template>
              </v-checkbox>
              <v-btn color="primary" type="submit">{{ model.form.translations['contactForm.Buttons.Submit'] }}
                <svg-icon :class="'size-24'" :icon="'arrow-right'"/>
              </v-btn>
              <div class="pt-5 font--small">
                {{ model.form.translations['reCaptcha.Description'] }}
                <a href="https://policies.google.com/privacy">{{
                    model.form.translations['reCaptcha.PrivacyPolicy']
                  }}</a>
                {{ model.form.translations['reCaptcha.And'] }}
                <a href="https://policies.google.com/terms">{{ model.form.translations['reCaptcha.Terms'] }}</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from '../components/Logo.vue'
import SvgIcon from '../components/SvgIcon.vue';
import {email, required, sameAs} from "@vuelidate/validators";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import {load} from "recaptcha-v3";

export default {
  name: "ContactBlockElement",
  components: {LogoComponent, SvgIcon},
  props: {
    model: Object
  },
  mounted() {
    load(this.model.form.captchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
  data: () => ({
    v$: useVuelidate(),
    recaptcha: Object,
    form: {
      name: "",
      email: "",
      message: "",
      acceptPrivacyPolicy: false
    },
  }),
  computed: {
    emailErrors() {
      const errors = []
      if (!this.v$.form.email.$dirty) return errors
      this.v$.form.email.email.$invalid && errors.push(this.model.form.translations["contactForm.Errors.InvalidEmail"])
      this.v$.form.email.required.$invalid && errors.push(this.model.form.translations["contactForm.Errors.RequiredField"])
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.v$.form.name.$dirty) return errors
      this.v$.form.name.required.$invalid && errors.push(this.model.form.translations["contactForm.Errors.RequiredField"])
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.v$.form.message.$dirty) return errors
      this.v$.form.message.required.$invalid && errors.push(this.model.form.translations["contactForm.Errors.RequiredField"])
      return errors
    },
    acceptPrivacyPolicyErrors() {
      const errors = []
      if (!this.v$.form.acceptPrivacyPolicy.$dirty) return errors
      this.v$.form.acceptPrivacyPolicy.required.$invalid && errors.push(this.model.form.translations["contactForm.Errors.AcceptPrivacyPolicy"])
      this.v$.form.acceptPrivacyPolicy.sameAs.$invalid && errors.push(this.model.form.translations["contactForm.Errors.AcceptPrivacyPolicy"])
      return errors
    },
  },
  validations() {
    return {
      form: {
        email: {required, email},
        name: {required},
        message: {required},
        acceptPrivacyPolicy: {required, sameAs: sameAs(true)},
      }
    };
  },
  methods: {
    async submitForm() {
      this.v$.$reset();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$errorToast(this.model.form.translations['contactForm.Errors.GeneralError']);
        return;
      }
      let current = this;
      current.recaptcha.execute("contactUsForm").then((token) => {
        axios({
          method: "POST",
          url: current.model.form.endpoint,
          data: {...current.form, captchaToken: token},
        }).then(function (response) {
          if (response.status === 200) {
            current.v$.$reset();
            current.form.email = "";
            current.form.name = "";
            current.form.message = "";
            current.form.acceptPrivacyPolicy = false;
            current.$successToast(current.model.thankYouTitle + '!!! ' + current.model.thankYouText);
          }
        }).catch(function (error) {
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).map((item) => {
              item[1].forEach((error) => {
                current.$errorToast(current.model.form.translations[error]);
              });
            });
          }
          if (error.response.data.detail) {
            current.$errorToast(current.model.form.translations[error.response.data.detail]);
          }
        })
      })
    }
  }
}
</script>