<template>
  <div v-if="false"></div>
</template>

<script>
import { integer } from "@vuelidate/validators";
import { i18n as i18 } from "../../portal/i18n";
import dayjs from "dayjs";

export default {
  name: "GlobalConfig",
  props: {
    culture: String,
    translations: Object,
    pageId: integer,
    mentee: Boolean,
    memberContext: Object,
  },
  beforeMount () {
    this.$store.commit('setCulture', this.culture);
    this.$store.commit('setPageId', this.pageId);
    this.$store.commit('setTranslation', this.translations);
    dayjs.locale(this.culture);
    i18.global.locale.value = this.culture.slice(0, 2);
    if (this.mentee) {
      this.$store.commit('setMentee', this.mentee);
    }
    if (this.memberContext) {
      this.$store.commit('setMemberContext', this.memberContext);
    }
  },
};
</script>
