<template>
  <div :data-id="model.id" class="kpi">
    <h2 class="font-secondary w-700" v-html="model.title"></h2>
    <p v-html="model.text"></p>
  </div>
</template>

<script>
export default {
  name: "KpiTile",
  props:{
    model: Object    
  }
}
</script>