<template>
  <div class="busy-indicator">
    <img class="busy-indicator__image" src="/images/spinner.png" alt="spinner"/>
  </div>
</template>

<script>
export default {
  name: "BusyIndicator"
}
</script>