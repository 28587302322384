<template>
  <div class="portal__help__faq">
    <div v-if="title" class="text-h6 text-center mb-5">{{ title }}</div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, index) of items" :key="index">
        <v-expansion-panel-title expand-icon="add" collapse-icon="remove">
          <p>{{ item.title }}</p>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="portal__help__faq__text">
            <div v-html="item.text"></div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import "./FaqComponent.scss";

export default {
  name: "FaqComponent",
  props: {
    items: Array,
    title: String,
  },
};
</script>
