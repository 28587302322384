<template>
  <article class="mt-120">
    <h1 v-html="model.title"></h1>
    <div class="article-info">
      <div class="article-details">
        <span class="article-author">{{ model.info.author }}</span>
        <span class="article-time">{{ model.info.date?.shortDate }}</span>
        <span class="article-dot"></span>
        <span class="article-time">{{ model.info.timeToRead }}&nbsp;{{ $store.state.translations['article.TimeToRead'] }}</span>
      </div>
      <social-media/>
    </div>
    <div class="article-excerpt">
      <image-box :model="model.image"></image-box>
      <h5 class="font-primary" v-html="model.shortDescription"></h5>
    </div>
    <div class="article-content">
      <slot></slot>
    </div>
    <div class="article-info">
      <div class="article-details">
        <span class="article-author">{{ model.info.author }}</span>
        <span class="article-dot"></span>
        <span class="article-time">{{ model.info.timeToRead }}&nbsp;{{ $store.state.translations['article.TimeToRead'] }}</span>
      </div>
      <social-media/>
    </div>
  </article>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
import SocialMedia from "@/common/SocialMedia.vue";

export default {
  name: "ArticlePage",
  components: { ImageBox, SocialMedia },
  props: {
    model: Object
  }
}
</script>
