import { createApp } from "vue";

import "./styles/main.scss";
import vuetify from "../plugins/vuetify";
import busyIndicatorPlugin from "../plugins/busyIndicatorPlugin";
import VueSplide from "@splidejs/vue-splide";
import Toast from "vue-toastification";
import toastPlugin from "../plugins/toastPlugin";
import VueSocialSharing from "vue-social-sharing";
import AOS from "aos";
import 'aos/dist/aos.css';

import PageWrapper from "./layout/PageWrapper";

// ============ PAGES ============
import MemberPage from "@/main/pages/MemberPage";
import LoginForm from "@/main/forms/LoginForm";
import ForgotPasswordForm from "@/main/forms/ForgotPasswordForm";
import ActivateAccountForm from "@/main/forms/ActivateAccountForm";
import RegisterForm from "@/main/forms/RegisterForm";
import ArticlePage from "@/main/pages/ArticlePage";
import SimpleTextPage from "@/main/pages/SimpleTextPage";
// ============ END PAGES ============
// ============ BLOCK ELEMENTS ============
import SplitTilesBlockElement from "@/main/blocks/SplitTilesBlockElement";
import HoverTilesBlockElement from "@/main/blocks/HoverTilesBlockElement";
import SliderBlockElement from "@/main/blocks/SliderBlockElement";
import TextBlockElement from "@/main/blocks/TextBlockElement";
import NewsletterBlockElement from "@/main/blocks/NewsletterBlockElement";
import IndicatorsBlockElement from "@/main/blocks/IndicatorsBlockElement";
import SwipeBlockElement from "@/main/blocks/SwipeBlockElement";
import PopularArticlesBlockElement from "@/main/blocks/PopularArticlesBlockElement";
import FeaturedArticleBlockElement from "@/main/blocks/FeaturedArticleBlockElement";
import ArticleListBlockElement from "@/main/blocks/ArticleListBlockElement";
import ImageBlockElement from "@/main/blocks/ImageBlockElement";
import ArticleTextBlockElement from "@/main/blocks/ArticleTextBlockElement";
import HeroBlockElement from "@/main/blocks/HeroBlockElement";
import ContactBlockElement from "@/main/blocks/ContactBlockElement";
import ArticleCategoriesBlockElement from "@/main/blocks/ArticleCategoriesBlockElement";
import FaqComponent from "@/common/faq/FaqComponent.vue";
import ArticlesSliderBlockElement from "@/main/blocks/ArticlesSliderBlockElement.vue";
// ============ END BLOCK ELEMENTS ============
import GlobalConfig from '@/common/global-config/GlobalConfig';
import { createStore } from 'vuex';

const store = createStore({
  state: {
    culture: null,
    translations: [],
    pageId: null,
  },
  mutations: {
    setCulture (state, value) {
      state.culture = value;
    },
    setPageId (state, value) {
      state.pageId = value;
    },
    setTranslation (state, translations) {
      state.translations = translations;
    },
  },
});

const app = createApp({ template: document.getElementById('app').innerHTML });

app.use(store);
// plugins
app.use(vuetify);
app.use(VueSplide);
app.use(busyIndicatorPlugin);
app.use(toastPlugin);
app.use(VueSocialSharing, {
  networks: {
    instagram: "https://",
  },
});
app.use(Toast, {
  position: "top-right",
  timeout: 10000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
});

// AOS
app.use(AOS.init());

// components
app.component('pageWrapper', PageWrapper);
app.component('global-config', GlobalConfig);

// ============ PAGES ============
app.component('memberPage', MemberPage);
app.component('loginForm', LoginForm);
app.component('register-form', RegisterForm);
app.component('forgotPasswordForm', ForgotPasswordForm);
app.component('activate-account-form', ActivateAccountForm);
app.component('article-page', ArticlePage);
app.component('simple-text-page', SimpleTextPage);
// ============ END PAGES ============

// ============ BLOCK ELEMENTS ============
app.component('split-tiles-block-element', SplitTilesBlockElement);
app.component('hover-tiles-block-element', HoverTilesBlockElement);
app.component('slider-block-element', SliderBlockElement);
app.component('text-block-element', TextBlockElement);
app.component('newsletter-block-element', NewsletterBlockElement);
app.component('indicators-block-element', IndicatorsBlockElement);
app.component('swipe-block-element', SwipeBlockElement);
app.component('popular-articles-block-element', PopularArticlesBlockElement);
app.component('featured-article-block-element', FeaturedArticleBlockElement);
app.component('article-list-block-element', ArticleListBlockElement);
app.component('image-block-element', ImageBlockElement);
app.component('article-text-block-element', ArticleTextBlockElement);
app.component('hero-block-element', HeroBlockElement);
app.component('contact-block-element', ContactBlockElement);
app.component('article-categories-block-element', ArticleCategoriesBlockElement);
app.component('faq-block-element', FaqComponent);
app.component("articles-slider-block-element", ArticlesSliderBlockElement);
// ============ END BLOCK ELEMENTS ============

// mount
app.mount('#app');
