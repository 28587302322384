<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section fill auto">
    <div :data-aos="getAnimation()"
         data-aos-delay="300"
         data-aos-duration="1000"
         :class="('t-' + model.fontColor.cssClass)"
         class="section-content md mt-120 mb-120 text-center">
      <h2 v-if="showBigHeader" class="mb-50" v-html="model.title"></h2>
      <h4 v-if="showBigHeader && model.text" class="mb-50 w-500" style="font-family: 'Inter', sans-serif;" v-html="model.text"></h4>
      <h6 v-if="showSmallHeader" class="mb-50" v-html="model.title"></h6>
      <p v-if="showSmallHeader && model.text" class="lg w-300 mb-50" style="font-family: 'Inter', sans-serif;" v-html="model.text"></p>
      <v-btn v-if="model.link" :href="model.link.link" class="btn" variant="flat">{{ model.link.text }}</v-btn>
    </div>
    <div v-if="model.backgroundImage" class="section-cover">
      <image-box :model="model.backgroundImage"></image-box>
    </div>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
import { getAnimation } from "@/common/aosAnimations/aosAnimation";

export default {
  name: "TextBlockElement",
  methods: { getAnimation },
  components: { ImageBox },
  props: {
    model: Object,
  },
  data: () => ({
    showBigHeader: false,
    showSmallHeader: false,
  }),
  mounted () {
    this.showBigHeader = this.model.titleStyle === 'big';
    this.showSmallHeader = this.model.titleStyle === 'compact';
  },
};
</script>
