<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section">
    <div class="section-content pt-160 pb-160">
      <div v-for="(item, index) of model.tiles" :key="index" class="auto-grid max-2 gap-120 sticky column-portrait">
        <div class="sticky-list">
          <div>
            <h2 class="mb-16 w-700" v-html="item.title"></h2>
            <h3 class="mb-45 w-400" v-html="item.text"></h3>
            <v-btn v-if="item.link" :href="item.link.link" class="btn" variant="flat">{{ item.link.text }}</v-btn>
          </div>
        </div>
        <div class="sticky-content pb-80">
          <image-box :model="item.image"></image-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "SwipeBlockElement",
  components:{ImageBox},
  props: {
    model: Object
  }
}
</script>