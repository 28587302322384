<template>
  <div :data-id="model.id" class="img-box">
    <div class="img-box-content">
      <h4 v-html="model.title"></h4>
    </div>
    <div class="img-box-hidden">
      <div>
        <div v-if="hiddenImage" class="img-box-hidden-image">
          <image-box :model="model.image"></image-box>
        </div>
        <h4 v-html="model.title"></h4>
        <div v-html="model.text"></div>
        <div v-if="model.link" class="btn">
          {{ model.link.text }}
        </div>
      </div>
    </div>
    <image-box :model="model.image" :css-class="'img-box-cover'"></image-box>
  </div>
</template>

<script>
import ImageBox from "@/main/components/ImageBox";
export default {
  name: "HoverTile",
  components:{ImageBox},
  props: {
    model: Object,
    hiddenImage : Boolean
  },
  data: () => ({}),
}
</script>