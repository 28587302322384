<template>
  <form ref="newsletterForm" @submit.prevent="submitForm">
    <v-text-field
        v-model="form.email"
        :error-messages="emailErrors"
        :label="model.translations['newsletter.Placeholders.Email']"
    ></v-text-field>
    <v-btn class="mt-5" type="submit" variant="outlined">{{ model.translations['newsletter.Placeholders.Button'] }}</v-btn>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {load} from "recaptcha-v3";
import {email, required} from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "NewsletterForm",
  props: {
    model: Object
  },
  data: () => ({
    v$: useVuelidate(),
    recaptcha: Object,
    form: {
      email: null,
    },
  }),
  mounted() {
    load(this.model.captchaSiteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.v$.form.email.$dirty) return errors
      this.v$.form.email.email.$invalid && errors.push(this.model.translations["newsletter.Errors.InvalidEmail"])
      this.v$.form.email.required.$invalid && errors.push(this.model.translations["newsletter.Errors.RequiredEmail"])
      return errors
    },
  },
  validations() {
    return {
      form: {
        email: {required, email}
      }
    };
  },
  methods: {
    async submitForm() {
      this.v$.$reset()
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$errorToast(this.model.translations['portal.ValidationMessages.InvalidForm']);
        return;
      }
      let current = this;
      current.$setBusy(true);
      current.recaptcha.execute("register").then((token) => {
        axios({
          method: "POST",
          url: current.model.endpoint,
          data: {...current.form, captchaToken: token},
        }).then(function (response) {
          if (response.status === 200) {
            current.v$.$reset();
            current.$setBusy(false);
            current.newsletter.email = "";
            current.$successToast(current.model.thankYouText);
          }
        }).catch(function (error) {
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).map((item) => {
              item[1].forEach((error) => {
                current.$errorToast(current.model.translations[error]);
              });
            });
          }
          if (error.response.data.detail) {
            current.$errorToast(current.model.translations[error.response.data.detail]);
          }
          current.$setBusy(false);
        })
      })
    }
  }
}
</script>