<template>
  <div v-bind:id="model.anchor"
       :data-id="model.id"
       class="section auto fill pt-80 pb-80"
       :class="model.backgroundColor.cssClass"
       :data-aos="getAnimation()"
       data-aos-delay="300"
       data-aos-duration="1000">
    <div class="section-content lg">
      <div class="auto-grid max-2 gap-80"
           :class="{'wrap-reverse' : model.imagePosition === 'right'}">
        <div>
          <image-box :model="model.image" css-class="stretch"></image-box>
        </div>
        <div class="auto-grid kpi-content"
             :class="[('t-' + model.fontColor.cssClass), (model.tiles.length === 1 ? 'center' : 'max-2 multiple')]">
          <!-- class: kpi-content -->
          <kpi-tile
              v-for="(item, index) of model.tiles"
              :key="index"
              :model="item"></kpi-tile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KpiTile from "@/main/components/KpiTile";
import ImageBox from "@/main/components/ImageBox";
import { getAnimation } from "@/common/aosAnimations/aosAnimation";

export default {
  name: "HeroBlockElement",
  methods: { getAnimation },
  components: { KpiTile, ImageBox },
  props: {
    model: Object,
  },
};
</script>
