<template>
  <div v-bind:id="model.anchor" :data-id="model.id">
    <splide :options="options">
      <splide-slide v-for="(item, index) of model.slides" :key="index">
        <div :class="[model.shadow?.cssClass, 'to-'+ (item.textPosition === 'right' ? 'left' : 'right')]"
             :data-id="item.id"
             class="section fill">
          <div :class="['t-' + item.fontColor.cssClass, {'align-bottom text-center': item.textPosition === 'center'}]"
               class="section-content">
            <div v-if="item.textPosition !== 'center'" class="auto-grid max-2">
              <div v-if="item.textPosition === 'right'">
              </div>
              <div>
                <h2 class="mb-40 h2" v-html="item.title"></h2>
                <p v-if="item.text" class="mb-6" v-html="item.text"></p>
                <v-btn v-if="item.link" :href="item.link.link" class="btn" variant="flat">{{ item.link.text }}</v-btn>
                <v-btn v-if="item.secondaryLink" :href="item.secondaryLink.link" class="btn ml-5" variant="flat">{{
                    item.secondaryLink.text
                  }}
                </v-btn>
              </div>
            </div>
            <h2 v-if="item.textPosition === 'center'" class="mb-40" v-html="item.title"></h2>
            <v-btn v-if="item.textPosition === 'center' && item.link" :href="item.link.link" class="btn" variant="flat">
              {{ item.link.text }}
            </v-btn>
            <v-btn v-if="item.textPosition === 'center' && item.secondaryLink"
                   :href="item.secondaryLink.link"
                   class="btn ml-5"
                   variant="flat">
              {{ item.secondaryLink.text }}
            </v-btn>
          </div>
          <div class="section-cover">
            <image-box v-if="this.$vuetify.display.mobile" :model="item.mobileBackgroundImage ?? item.backgroundImage"></image-box>
            <image-box v-if="!this.$vuetify.display.mobile" :model="item.backgroundImage"></image-box>
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import ImageBox from "@/main/components/ImageBox";

export default {
  name: "SliderBlockElement",
  components: { Splide, SplideSlide, ImageBox },
  props: {
    model: Object,
  },
  data: () => ({
    options: {
      type: "fade",
      pagination: true,
      rewind: true,
      autoplay: true,
      pauseOnHover: false,
      arrows: false,
      speed: 20,
      interval: 15000,
    },
  }),
};
</script>
