export default {
    install: (app) => {
        app.config.globalProperties.$setBusy = (option) => {
            if (option) {
                document.body.classList.add('busy');
            } else {
                document.body.classList.remove('busy');
            }
        }
    }
}