<template>
  <input id="OpenNav" hidden type="checkbox" />
  <input id="OpenAccessibility" hidden type="checkbox" />
  <div class="navbar">
    <div class="navbar-logo">
      <a href="/"><logo-component /></a>
    </div>
    <div v-if="!hideMenu" class="navbar-menu">
      <a :href="link.link"
         class="btn navbar-menu-item text-decoration-none"
         :class="{'active' : link.isActive}"
         v-for="(link, index) in model.menu"
         :key="index">{{ link.text }}</a>
    </div>

    <div class="navbar-accessibility">
      Opcje dostępności
      <div class="navbar-action-item" v-on:click="ContrastMode">
        <svg-icon :icon="'contrast'" />
        <span>Kontrast</span>
      </div>
      <div class="navbar-action-item" @click="underlineMode">
        <svg-icon :icon="'underline'" />
        <span>Linki</span>
      </div>
      <div class="navbar-action-item" v-on:click="ScaleUI('inc')">
        <svg-icon :icon="'scale-up'" />
      </div>
      <div class="navbar-action-item" v-on:click="ScaleUI('dec')">
        <svg-icon :icon="'scale-down'" />
      </div>
    </div>

    <div v-if="!hideMenu" class="navbar-action">
      <label class="navbar-action-item accessibility" for="OpenAccessibility">
        <svg-icon :icon="'eye'" />
      </label>
      <label class="navbar-action-item menu" for="OpenNav">
        <div class="menu-icon">
          <div class="menu-line"></div>
          <div class="menu-line"></div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import LogoComponent from './Logo.vue';
import SvgIcon from './SvgIcon.vue';

export default {
  name: "NavBar",
  components: { LogoComponent, SvgIcon },
  data: () => ({}),
  props: {
    transparent: Boolean,
    hideMenu: Boolean,
    model: Object,
  },

  methods: {
    ScaleUI: function (action) {
      const html = document.querySelector('html');
      let fontSize = parseFloat(window.getComputedStyle(html, null).getPropertyValue('font-size'));
      if (action == 'inc' && fontSize < 20) {
        fontSize++;
        html.style.cssText = 'font-size:' + fontSize + 'px';
      } else if (action == 'dec' && fontSize > 12) {
        fontSize--;
        html.style.cssText = 'font-size:' + fontSize + 'px';
      }
    },

    ContrastMode: function () {
      const body = document.querySelector('body');

      if (!body.classList.contains('contrast')) {
        body.classList.add('contrast');
        window.localStorage.setItem("contrast", "true");
      } else {
        body.classList.remove('contrast');
        window.localStorage.setItem("contrast", "false");
      }
    },
    setContrast () {
      if (window.localStorage.getItem('contrast') === 'true' && !document.body.classList.contains('contrast')) {
        document.body.classList.add('contrast');
      } else {
        document.body.classList.remove('contrast');
      }
    },
    underlineMode () {
      document.body.classList.toggle('underline');
      if (document.body.classList.contains('underline')) {
        window.localStorage.setItem('underline', 'true');
      } else {
        window.localStorage.setItem('underline', 'false');
      }
    },

    setUnderline () {
      if (window.localStorage.getItem('underline') === 'true' && !document.body.classList.contains('underline')) {
        document.body.classList.add('underline');
      } else {
        document.body.classList.remove('underline');
      }
    },
  },

  mounted () {
    this.setContrast();
    this.setUnderline();
    const navbar = document.querySelector('.navbar');
    const body = document.querySelector('body');
    let currentPos = window.scrollY;
    let prevPos = 0;

    if (!this.transparent) body.classList.add('navbar-space');

    const navbarControl = (windowOffset) => {
      if (windowOffset == 0 && this.transparent) {
        navbar.classList.add('transparent');
      } else {
        navbar.classList.remove('transparent');
      }
    };

    navbarControl(currentPos);

    window.addEventListener('scroll', () => {
      currentPos = window.scrollY;
      if (this.transparent) navbarControl(currentPos);

      if (currentPos > prevPos) {
        navbar.classList.add('hidden');
      } else {
        navbar.classList.remove('hidden');
      }
      prevPos = currentPos;
    });
  },
};

</script>
