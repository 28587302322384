<template>
  <svg width="225" height="48" viewBox="0 0 225 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5023_23541)">
      <g clip-path="url(#clip1_5023_23541)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5885 41.789C26.0277 42.7635 26.2465 43.8301 26.2465 44.9786H32.8065C32.7637 42.3949 32.3377 40.2788 31.5185 38.6285C30.6598 36.9561 29.6877 35.654 28.6004 34.7188C27.3651 33.4628 25.8501 32.5276 24.0489 31.9235C22.3102 31.3672 19.8395 31.0839 16.6845 31.0532V31.0378C14.3849 31.0361 12.7054 30.9286 11.6445 30.7153C10.6197 30.4968 9.71989 30.0531 8.94677 29.379C8.21313 28.8756 7.63741 28.1384 7.21795 27.1639C6.77876 26.2287 6.55998 25.1468 6.55998 23.9061C6.55998 22.7576 6.77876 21.691 7.21795 20.7166C7.63741 19.7831 8.21313 19.022 8.94677 18.4383C9.71989 17.8513 10.6197 17.4298 11.6445 17.1653C12.712 16.9059 14.3849 16.7779 16.6664 16.7779H16.6845V9.96875H16.6664C13.2351 9.96875 10.5999 10.2623 8.75761 10.851C6.95641 11.4569 5.44143 12.3903 4.20444 13.6464C3.11879 14.5816 2.14499 15.8854 1.28798 17.5561C0.468805 19.2063 0.0427682 21.3224 0 23.9061C0.0427682 26.5752 0.468805 28.7391 1.28798 30.3876C2.14499 32.0122 3.11879 33.2768 4.20444 34.1642C5.44143 35.5072 6.95641 36.4646 8.75761 37.0295C10.4963 37.5414 12.967 37.8026 16.122 37.8316V37.8503H16.1401C18.4216 37.8503 20.0945 37.9783 21.162 38.236C22.1868 38.5022 23.0866 38.9238 23.8597 39.5125C24.595 40.0927 25.1691 40.8556 25.5885 41.789Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3701 13.2598C20.6071 14.5158 22.1205 15.451 23.9233 16.0568C25.6604 16.6114 28.131 16.8947 31.2877 16.9254V16.9408C33.5856 16.9425 35.2668 17.0517 36.3261 17.2633C37.3525 17.4818 38.2523 17.9255 39.0238 18.6013C39.7591 19.103 40.3331 19.8402 40.7526 20.8146C41.1934 21.7498 41.4106 22.8335 41.4106 24.0724C41.4106 25.221 41.1934 26.2875 40.7526 27.262C40.3331 28.1955 39.7591 28.9583 39.0238 29.5402C38.2523 30.129 37.3525 30.5488 36.3261 30.815C35.2585 31.0727 33.5856 31.2007 31.3041 31.2007H31.2877V38.0098H31.3041C34.7371 38.0098 37.3706 37.718 39.2129 37.1275C41.0141 36.5234 42.5291 35.5882 43.7661 34.3322C44.8518 33.3987 45.8256 32.0949 46.6826 30.4225C47.5017 28.774 47.9278 26.6579 47.9705 24.0724C47.9278 21.4034 47.5017 19.2395 46.6826 17.5927C45.8256 15.9646 44.8518 14.7018 43.7661 13.8144C42.5291 12.4713 41.0141 11.514 39.2129 10.9491C37.4742 10.4371 35.0036 10.176 31.8486 10.147V10.1282H31.8305C29.549 10.1282 27.8761 10.0003 26.8085 9.74086C25.7837 9.47635 24.884 9.05654 24.1108 8.46778C23.3772 7.88585 22.8015 7.12302 22.382 6.18954C21.9428 5.2151 21.724 4.14851 21.724 3H15.1641C15.2068 5.58371 15.6345 7.70154 16.452 9.34836C17.3107 11.0208 18.2829 12.3263 19.3701 13.2598Z" fill="currentColor"/>
      </g>
    </g>
    <path d="M70.2015 35.7053C74.7766 35.7053 77.6638 33.2535 77.6638 29.4479C77.6638 26.5599 75.3836 24.3788 72.4964 24.2435V24.1231C74.8654 23.7922 76.6718 21.8518 76.6718 19.415C76.6718 16.0607 74.1399 14 69.9349 14H60V35.7053H70.2015ZM65.4339 17.9109H68.4987C70.3199 17.9109 71.3859 18.8134 71.3859 20.3326C71.3859 21.8969 70.2015 22.8446 68.2322 22.8446H65.4339V17.9109ZM65.4339 31.7944V26.1989H68.632C70.8677 26.1989 72.1411 27.1916 72.1411 28.9666C72.1411 30.8017 70.8973 31.7944 68.6616 31.7944H65.4339Z" fill="currentColor"/>
    <path d="M90.2638 30.5309C89.8937 31.644 88.798 32.3209 87.3914 32.3209C85.4962 32.3209 84.2081 30.9671 84.2081 29.0117V28.6808H95.0906V27.1766C95.0906 22.1677 91.9961 18.9337 87.1397 18.9337C82.2093 18.9337 79.1148 22.3031 79.1148 27.5978C79.1148 32.8925 82.1796 36.0964 87.3174 36.0964C91.5371 36.0964 94.4984 33.9153 95.0018 30.5309H90.2638ZM87.2286 22.7242C88.8868 22.7242 90.0565 23.8975 90.1454 25.6423H84.2821C84.4154 23.9426 85.6147 22.7242 87.2286 22.7242Z" fill="currentColor"/>
    <path d="M116.856 35.7053V14H111.437V22.5136H102.923V14H97.4892V35.7053H102.923V26.966H111.437V35.7053H116.856Z" fill="currentColor"/>
    <path d="M124.274 35.946C126.213 35.946 128.182 35.0284 129.056 33.3738H129.16V35.7053H134.268V24.4691C134.268 21.1749 131.41 18.9337 127.102 18.9337C122.601 18.9337 119.787 21.1448 119.639 24.4992H124.362C124.51 23.4914 125.414 22.7393 126.835 22.7393C128.212 22.7393 129.13 23.4613 129.13 24.7699V25.7025L125.354 25.9281C121.179 26.1838 118.855 27.8986 118.855 30.9671C118.855 33.9304 121.283 35.946 124.274 35.946ZM126.139 32.2758C124.895 32.2758 124.052 31.644 124.052 30.6061C124.052 29.6435 124.821 28.9967 126.198 28.8914L129.13 28.6958V29.824C129.13 31.283 127.738 32.2758 126.139 32.2758Z" fill="currentColor"/>
    <path d="M147.001 19.0841C144.677 19.0841 142.929 20.2724 142.115 22.2429H142.011V19.3248H136.785V41H142.041V32.9075H142.145C142.929 34.8028 144.706 35.946 147.075 35.946C151.088 35.946 153.442 32.8624 153.442 27.5226C153.442 22.1677 151.073 19.0841 147.001 19.0841ZM145.047 31.7794C143.196 31.7794 142.026 30.1248 142.011 27.5226C142.026 24.9354 143.211 23.2507 145.047 23.2507C146.897 23.2507 148.082 24.9203 148.082 27.5226C148.082 30.1248 146.912 31.7794 145.047 31.7794Z" fill="currentColor"/>
    <path d="M158.146 35.9008C159.671 35.9008 160.9 34.6524 160.9 33.1031C160.9 31.5538 159.671 30.3053 158.146 30.3053C156.621 30.3053 155.407 31.5538 155.407 33.1031C155.407 34.6524 156.621 35.9008 158.146 35.9008Z" fill="currentColor"/>
    <path d="M187.64 35.7053V14H181.363L175.973 28.1844H175.855L170.465 14H164.187V35.7053H168.97V22.1526H169.088L174.359 35.7053H177.469L182.754 22.1526H182.858V35.7053H187.64Z" fill="currentColor"/>
    <path d="M201.173 30.5309C200.803 31.644 199.707 32.3209 198.301 32.3209C196.406 32.3209 195.117 30.9671 195.117 29.0117V28.6808H206V27.1766C206 22.1677 202.906 18.9337 198.049 18.9337C193.119 18.9337 190.024 22.3031 190.024 27.5978C190.024 32.8925 193.089 36.0964 198.227 36.0964C202.447 36.0964 205.408 33.9153 205.911 30.5309H201.173ZM198.138 22.7242C199.796 22.7242 200.966 23.8975 201.055 25.6423H195.191C195.325 23.9426 196.524 22.7242 198.138 22.7242Z" fill="currentColor"/>
    <defs>
      <clipPath id="clip0_5023_23541">
        <rect width="48" height="48" fill="currentColor"/>
      </clipPath>
      <clipPath id="clip1_5023_23541">
        <rect width="48" height="42" fill="currentColor" transform="translate(0 3)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>

export default {
    name: "LogoComponent",
    data: () => ({
    }),
}
</script>