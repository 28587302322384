<template>
  <div class="login-form">
    <h1 v-if="!submitted" class="mb-45" v-html="currentStep?.title"></h1>
    <form v-if="model.forgotPassword && isForgotPasswordForm" class="w-100" @submit.prevent="submitForgotForm">
      <div class="mb-5">
        <v-text-field
            v-model="forgotPasswordForm.email"
            :error-messages="forgotPasswordEmailErrors"
            :label="model.translations['loginPage.Placeholders.Email']"
            type="email"
            variant="outlined"
        ></v-text-field>
      </div>
      <div class="btn-wrapper">
        <v-btn class="pa-2 w-100" color="primary" size="large" type="submit">
          {{ model.forgotPassword.text }}
        </v-btn>
        <v-btn class="pa-2 w-100" color="primary" size="small" variant="outlined"
               @click="toggleForgotPasswordForm">
          {{ $store.state.translations['portal.Placeholders.Cancel'] }}
        </v-btn>
      </div>
    </form>
    <form v-if="!submitted && !isForgotPasswordForm" class="w-100" @submit.prevent="submitForm">
      <div v-if="currentStepIndex === 1" class="mb-5">
        <v-text-field
          v-model="form.email"
          :error-messages="emailErrors"
          :label="model.translations['loginPage.Placeholders.Email']"
          type="email"
          variant="outlined"
          v-on:keydown.enter.prevent="next"
        ></v-text-field>
      </div>
      <div v-if="currentStepIndex === 2" class="mb-5">
        <v-text-field
          v-model="form.password"
          :error-messages="passwordErrors"
          :label="model.translations['loginPage.Placeholders.Password']"
          type="password"
          variant="outlined"
        ></v-text-field>
      </div>
      <div class="btn-wrapper">
        <v-btn v-if="!showNextButton" class="pa-2 w-100" color="primary" size="large" type="submit">
          {{ model.translations['loginPage.Buttons.Login'] }}
        </v-btn>
        <v-btn v-if="showPreviousButton" class="pa-2 w-100" color="primary" size="large" variant="outlined"
               @click="prev">
          {{ model.translations['portal.Placeholders.Previous'] }}
        </v-btn>
        <v-btn v-if="showNextButton" class="pa-2 w-100" color="primary" size="large" @click="next">
          {{ model.translations['loginPage.Buttons.Next'] }}
        </v-btn>
      </div>
    </form>
    <v-btn v-if="model.forgotPassword && !isForgotPasswordForm" class="mt-5 text-decoration-underline" size="small" variant="plain" @click="toggleForgotPasswordForm">
      {{model.forgotPassword.text}}
    </v-btn>
    <div class="bh_divider">
      {{ model.translations['loginPage.Labels.NotExistingAccount'] }}
    </div>

    <v-btn class="w-100" size="large" color="primary" variant="outlined" :href="model.register.link">
      {{model.register.text}}
    </v-btn>
  </div>
</template>

<script>
  import useVuelidate from '@vuelidate/core'
  import { email, requiredIf } from '@vuelidate/validators'
  import axios from 'axios'

  export default {
    name: 'LoginForm',
    props: {
      model: Object,
      showConfirm: Boolean
    },
    data: () => ({
      v$: useVuelidate(),
      currentStepIndex: 1,
      steps: [],
      submitted: false,
      responseErrors: [],
      isForgotPasswordForm : false,
      form: {
        email: null,
        password: null
      },
      forgotPasswordForm: {
        email: null,
        url: null,
        culture : null
      }
    }),
    mounted () {
      this.steps.push({ title: this.model.title, })
      this.steps.push({ title: this.model.title, })
      this.forgotPasswordForm.url = this.model.forgotPassword.link;
      this.forgotPasswordForm.culture = this.model.culture;
      
      if(this.showConfirm){
        this.$successToast(this.model.translations["loginPage.Account.Confirmed"]);
      }
    },
    computed: {
      currentStep () {
        return this.steps[this.currentStepIndex - 1]
      },
      showPreviousButton () {
        return this.currentStepIndex > 1
      },
      showNextButton () {
        return this.currentStepIndex < this.steps.length
      },
      emailErrors () {
        const errors = []
        if (!this.v$.form.email.$dirty)
          return errors
        this.v$.form.email.email.$invalid && errors.push(this.model.translations['portal.ValidationMessages.InvalidEmail'])
        this.v$.form.email.required.$invalid && errors.push(this.model.translations['portal.ValidationMessages.RequiredField'])
        return errors
      },
      forgotPasswordEmailErrors () {
        const errors = []
        if (!this.v$.forgotPasswordForm.email.$dirty)
          return errors
        this.v$.forgotPasswordForm.email.email.$invalid && errors.push(this.model.translations['portal.ValidationMessages.InvalidEmail'])
        this.v$.forgotPasswordForm.email.required.$invalid && errors.push(this.model.translations['portal.ValidationMessages.RequiredField'])
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.v$.form.password.$dirty)
          return errors
        this.v$.form.password.$invalid && errors.push(this.model.translations['portal.ValidationMessages.RequiredField'])
        return errors
      },
    },
    validations () {
      return {
        form: {
          email: {
            required: requiredIf(this.currentStepIndex >= 1 && !this.isForgotPasswordForm),
            email
          },
          password: {
            required: requiredIf(this.currentStepIndex >= 2 && !this.isForgotPasswordForm)
          },
        },
        forgotPasswordForm: {
          email: {
            required: requiredIf(this.isForgotPasswordForm),
            email
          },
        }
      }
    },
    methods: {
      toggleForgotPasswordForm() {
        this.v$.$reset();
        this.isForgotPasswordForm = !this.isForgotPasswordForm;
        if (this.isForgotPasswordForm){
          this.forgotPasswordForm.email = this.form.email;
        }
        else{
          this.forgotPasswordForm.email = null;
        }
      },
      async prev () {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect)
          return
        this.v$.$reset()
        this.currentStepIndex = this.currentStepIndex - 1
      },
      async next () {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect)
          return
        this.v$.$reset()
        this.currentStepIndex = this.currentStepIndex + 1
      },
      async submitForm () {
        this.v$.$reset()
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          this.$errorToast(this.model.translations['portal.ValidationMessages.InvalidForm'])
          return
        }
        let current = this
        current.$setBusy(true)
        axios({
          method: 'POST',
          url: current.model.endpoint,
          data: { ...current.form },
        }).then(function (response) {
          if (response.status === 200) {
            current.v$.$reset()
            current.$setBusy(false)
            window.location.href = current.model.loggedUserRedirect
          }
        }).catch(function (error) {
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).map((item) => {
              item[1].forEach((error) => {
                current.$errorToast(current.model.translations[error])
              })
            })
          }
          if (error.response.data.detail) {
            current.$errorToast(current.model.translations[error.response.data.detail])
          }
          current.$setBusy(false)
        })
      },
      async submitForgotForm () {
        this.v$.$reset()
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          this.$errorToast(this.model.translations['portal.ValidationMessages.InvalidForm'])
          return
        }
        let current = this
        current.$setBusy(true)
        axios({
          method: 'POST',
          url: current.model.forgotPasswordEndpoint,
          data: { ...current.forgotPasswordForm },
        }).then(function (response) {
          if (response.status === 200) {
            current.v$.$reset()
            current.$setBusy(false)
            current.$successToast(current.$store.state.translations['forgotPasswordPage.EmailSent']);
            current.toggleForgotPasswordForm();
          }
        }).catch(function (error) {
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).map((item) => {
              item[1].forEach((error) => {
                current.$errorToast(current.model.translations[error])
              })
            })
          }
          if (error.response.data.detail) {
            current.$errorToast(current.model.translations[error.response.data.detail])
          }
          current.$setBusy(false)
        })
      }
    },
  }
</script>
