<template>
  <div class="chips-grid">
    <div v-for="(filter, index) in items" :key="index" class="chips-item">
      <a :href="url + '?tags=' + filter.key">{{ filter.name }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChipsComponent',
  props: {
    items: Array,
    url: String
  },
}
</script>