<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section auto pt-80 pb-80">
    <div class="section-content">
      <div class="chips-section">
        <div class="chips-desc">
          <h2 class="mb-20" v-html="model.title"></h2>
          <p class="sm" v-html="model.text"></p>
        </div>
        <chips-component :items="model.categories" :url="articlesPageUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import ChipsComponent from '../components/ChipsComponent.vue';

export default {
  name: "ArticleCategoriesBlockElement",
  components: { ChipsComponent },
  props: {
    model: Object,
    articlesPageUrl: String,
  },
};
</script>
