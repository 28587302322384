<template>
<div v-bind:id="model.anchor" :data-id="model.id">
  <div class="auto-grid max-2">
    <hover-tile :model="item" hidden-image v-for="(item, index) of model.tiles" :key="index"> </hover-tile>
    <div class="float-arrow">
      <svg width="24" height="16" viewBox="0 0 24 16" fill="none">
        <path
            d="M23 9L24 9L24 7L23 7L23 9ZM0.292894 7.29289C-0.0976309 7.68341 -0.0976312 8.31657 0.292893 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07106 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6568L2.41421 7.99999L8.07107 2.34314C8.46159 1.95262 8.46159 1.31945 8.07107 0.928927C7.68055 0.538403 7.04738 0.538403 6.65686 0.928927L0.292894 7.29289ZM23 7L1 6.99999L1 8.99999L23 9L23 7Z"
            fill="currentColor" />
      </svg>
    </div>
  </div>
</div>
</template>

<script>
import HoverTile from "@/main/components/HoverTile";
export default {
  name: "SplitTilesBlockElement",
  components:{HoverTile},
  props: {
    model: Object,
  },
}
</script>
