<template>
<div v-bind:id="model.anchor" :data-id="model.id">
  <div class="auto-grid max-4">
    <hover-tile :model="item" v-for="(item, index) of model.tiles" :key="index"> </hover-tile>
  </div>
</div>
</template>

<script>
import HoverTile from "@/main/components/HoverTile";
export default {
  name: "SplitTilesBlockElement",
  components:{HoverTile},
  props: {
    model: Object,
  },
}
</script>
