<template>
  <div v-bind:id="model.anchor" :data-id="model.id" class="section auto mt-90 mb-120">
    <div class="section-content lg">
      <h3 v-if="model.title" class="mb-50" v-html="model.title"></h3>
      <div class="auto-grid max-3 gap-20">
        <article-item v-for="(item, index) of model.articles" :key="index" :model="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleItem from "@/main/components/ArticleItem";

export default {
  name: "PopularArticlesBlockElement",
  components: {ArticleItem},
  props: {
    model: Object
  },
}
</script>