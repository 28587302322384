import { createI18n } from "vue-i18n";
import pl from "@/portal/translations/pl.json";
import en from "@/portal/translations/en.json";

function customRule (choice) {
  if (choice === 0 || choice % 10 === 0 || (choice >= 5 && choice < 22)) {
    return 0;
  }

  if (choice === 1) {
    return 1;
  }
  if (choice % 10 > 1 && choice % 10 < 5) {
    return 2;
  } else {
    return 0;
  }
}

export const i18n = createI18n({
  legacy: false,
  locale: 'pl',
  pluralRules: { pl: customRule },
  fallbackLocale: 'pl',
  messages: {
    pl: pl,
    en: en,
  },
});
