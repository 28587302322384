<template>
  <article class="mt-120">
    <h1 v-html="model.title"></h1>
    <div class="article-content">
      <p v-html="model.text"></p>
    </div>
  </article>
</template>

<script>
export default {
  name: "SimpleTextPage",
  props: {
    model: Object,
  },
}
</script>